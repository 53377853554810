import React from "react";



const Spinner = () => (
    
<div className="spinner-module">
  <div className="spinner">
    <div className="double-bounce1"></div>
    <div className="double-bounce2"></div>
  </div>
</div>

);

export default Spinner;