import React, { Component } from "react";
import { Link } from "gatsby";
//import { Index } from "elasticlunr";

export default class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: ``,
      results: [],
      isActive: false,
    };
  }

  render() {
    return (
      <div className={`navbar-item ${this.state.isActive ? "is-active" : ""}`}>
        <input
          className="input navbar-link is-rounded is-primary"
          type="text"
          value={this.state.query}
          onChange={this.search}
          placeholder="Search"
        />
         <div className="navbar-dropdown">
         {this.state.results.map((page,index) => (
            <Link className="navbar-item" key={index} to={page.path}>
              {page.context.metaDescription}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  getOrCreateIndex = () =>
  
    this.index ? this.index : this.props.searchIndex;
    

  search = (evt) => {
    const query = evt.target.value;
    console.log(this.results);
    let newList = [];
    this.index = this.getOrCreateIndex();
    this.results = this.index.nodes.filter(node => {
      return node.context.metaDescription.toLowerCase().includes(this.state.query.toLowerCase())
      
      })
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: query !=''? this.results : [],
      isActive: !!query,
      
    });
  };
}
