import React from "react";
import config from "../../../config";
import { Link,graphql,StaticQuery, useStaticQuery } from "gatsby";
import logo from "../../assets/img/logo-white.png";

const Footer = ({data= useStaticQuery(graphql`
query footerPageQuery {
 
  allContentfulServicePages(sort: {order: ASC, fields: rank}) {
    edges {
      node {
        metaDescription
        metaTitle
        rank
        slug
        title
        id
      }
    }
  }
}

`)}) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="content has-text-centered">
          <section className="section">
            <div className="columns">
              <div className="column is-12">
                <div className="content">
                  <div className="columns is-multiline">
                    <div className="column is-3 navbar-item logo">
                      <Link to="/" >
                        <img src={logo} alt="white ideal ops logo" className="img-res ponsive"></img>
                    </Link>


                   <a href="tel:‪5127109557‬"><p>‪(512) 710-9557‬</p></a>
                    </div>
                    <div className="column is-6 topline">
                      <Link to="/services/" >
                        <p className="footer-link">Services</p>
                    </Link>
                    <div >
          { data ? data.allContentfulServicePages.edges.map((item,index) => (
            <div key={index} className="sublink">
            <Link key={index} to={"/services/"+ item.node.slug}>
              <p>{item.node.title}</p>
              </Link>
              </div>
          )):""}
          </div>
                    </div>
                    <div className="column is-2 topline">
                      <Link to="/about/" >
                        <p className="footer-link">About</p>
                    </Link>
                    </div>
                    <div className="column is-1 topline">
                      <Link to="/blog/" >
                        <p className="footer-link">Blog</p>
                    </Link>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="columns">
              <div className="column is-12">
                <div className="content">
                  <div className="columns is-multiline floatRight">
                    <div>
                 
                    <Link className="button is-primary is-outlined buttonAlign" to="/contact/">
                         Contact Us
                        </Link>
                    </div>
                    <div >
         
                    <a href="https://www.facebook.com/idealOpsSFDCAdobe/"><svg xmlns="http://www.w3.org/2000/svg" aria-labelledby="socialTitle socialDesc" width="54" height="54" role="img" viewBox="0 0 24 24"><title id="socialTitle">Ideal Ops - Salesforce. Marketo. Facebook Link.</title><desc id="socialDesc">Ideal Ops Consulting Salesforce Professional Services Facebook Link.</desc><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z"/></svg></a>          
                    <a href="https://twitter.com/OpsSfdc"><svg xmlns="http://www.w3.org/2000/svg" aria-labelledby="socialTitle socialDesc" width="54" height="54" role="img" viewBox="0 0 24 24"><title id="socialTitle">Ideal Ops - Salesforce. Marketo. Twitter feed.</title><desc id="socialDesc">Ideal Ops Consulting Salesforce Professional Services Twitter Feed</desc><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.5 8.778c-.441.196-.916.328-1.414.388.509-.305.898-.787 1.083-1.362-.476.282-1.003.487-1.564.597-.448-.479-1.089-.778-1.796-.778-1.59 0-2.758 1.483-2.399 3.023-2.045-.103-3.86-1.083-5.074-2.572-.645 1.106-.334 2.554.762 3.287-.403-.013-.782-.124-1.114-.308-.027 1.14.791 2.207 1.975 2.445-.346.094-.726.116-1.112.042.313.978 1.224 1.689 2.3 1.709-1.037.812-2.34 1.175-3.647 1.021 1.09.699 2.383 1.106 3.773 1.106 4.572 0 7.154-3.861 6.998-7.324.482-.346.899-.78 1.229-1.274z"/></svg></a>
                    <a href="https://www.linkedin.com/company/ideal-ops/"><svg xmlns="http://www.w3.org/2000/svg" aria-labelledby="socialTitle socialDesc" width="54" height="54" role="img" viewBox="0 0 24 24"><title id="socialTitle">Ideal Ops - Salesforce. Marketo. LinkedIn.</title><desc id="socialDesc">Ideal Ops Consulting Salesforce Professional Services LinkedIn</desc><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 8c0 .557-.447 1.008-1 1.008s-1-.45-1-1.008c0-.557.447-1.008 1-1.008s1 .452 1 1.008zm0 2h-2v6h2v-6zm3 0h-2v6h2v-2.861c0-1.722 2.002-1.881 2.002 0v2.861h1.998v-3.359c0-3.284-3.128-3.164-4-1.548v-1.093z"/></svg></a>
                   <a href="https://www.youtube.com/channel/UCqF72iLgWtJgr9wvUudcA-A"><svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" role="img" viewBox="0 0 24 24"><title id="socialTitle">Ideal Ops - Salesforce. Marketo. Youtube Feed.</title><desc id="socialDesc">Ideal Ops Consulting Salesforce Professional Services Youtube Page</desc><path d="M16.23 7.102c-2.002-.136-6.462-.135-8.461 0-2.165.148-2.419 1.456-2.436 4.898.017 3.436.27 4.75 2.437 4.898 1.999.135 6.459.136 8.461 0 2.165-.148 2.42-1.457 2.437-4.898-.018-3.436-.271-4.75-2.438-4.898zm-6.23 7.12v-4.444l4.778 2.218-4.778 2.226zm2-12.222c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z"/></svg></a>
                   <Link to="/rss.xml" className="floatRight"><svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" aria-labelledby="socialTitle socialDesc" role="img" viewBox="0 0 24 24"><title id="socialTitle">Ideal Ops - Salesforce. Marketo. Blog feed.</title><desc id="socialDesc">Ideal Ops Consulting Salesforce Professional Services Blog</desc><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.29 14.646c0 .748-.607 1.354-1.354 1.354-.749 0-1.356-.606-1.356-1.354 0-.747.607-1.353 1.355-1.353.748.001 1.355.606 1.355 1.353zm-2.71-5.237v2.004c2.521.025 4.567 2.068 4.592 4.587h2.008c-.026-3.629-2.965-6.566-6.6-6.591zm0-1.404c4.407.02 7.98 3.581 7.993 7.995h2.007c-.012-5.513-4.48-9.981-10-10v2.005z"/></svg></Link>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
          <section className="section">
          <hr/>

            <div className="columns">
              <div className="column is-12">
                <div className="content">
                  <div className="columns is-multiline">
                    <div className="column is-5">
                      <h6>{config.copyright}</h6>
                    </div>

                    <div className="column is-7 has-text-right push-right">
                    <Link to="/privacy-policy/"><h6>Privacy Policy </h6></Link>&nbsp;<h6> | </h6> &nbsp;
                    <Link to="/terms-and-conditions/"><h6>Terms of Service</h6></Link>&nbsp;<h6> | </h6>&nbsp;<a href="https://www.ideal-ops.com/sitemap.xml"><h6>Site Map</h6></a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
