import React from "react";
import { Link, graphql, StaticQuery, useStaticQuery } from "gatsby";
import SearchBox from "../SearchBox";
import logo from "../../assets/img/idealops.png";
import { Index } from "elasticlunr";
import Slide from "react-reveal/Slide";


const NavBar = ({ toggleNavbar,toggleMobileVisibility,toggleDropDown,dropArrow,handleHover,exitHover, isActive, isVisible, isHidden, data= useStaticQuery(graphql`
query navPageQuery {
  allContentfulServicePages(sort: {order: ASC, fields: rank}) {
    edges {
      node {
        metaDescription
        metaTitle
        rank
        slug
        title
        id
      }
    }
  }
  allSitePage(limit: 500, sort: {order: DESC, fields: context___date}, filter: {path: {regex: "/blog/ || /services/"}, context: {metaDescription: {nin:[null]}}}) {
    nodes {
      path
      context {
        metaDescription
        metaTitle
        title
        pathPrefix
        content {
          json {
            content {
              content {
                value
              }
            }
          }
        }
        slug
        date
      }
    }
  }
}



`) } )  =>  ( 
  <Slide top duration={500} when={isVisible}>
    <nav className="navbar is-fixed-top" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item logo">
          <img src={logo} alt="ideal ops logo" className="img-res ponsive" />
        </Link>
        <button
          className={`button navbar-burger ${isActive ? "is-active" : ""}`}
          data-target="navMenu"
          onClick={toggleNavbar}
        >
          <span />
          <span />
          <span />
        </button>
      </div>
      <div className={`navbar-menu ${isActive ? "is-active" : ""}`}>
        <div className="navbar-end">
          {<SearchBox searchIndex={data.allSitePage} />}
          <div className={`navbar-item has-dropdown is-hoverable is-mega `}>
         <a onClick={toggleMobileVisibility} className={`navbar-link ${dropArrow ? "arrowBack" : ""} ` } href={`${isHidden ? "#" : "/services/"} `}>
            Services
          </a>     
          <div className={`navbar-dropdown ${isHidden ? "" : "is-hidden-mobile is-hidden-tablet-only"}  `}>
          <div className="container is-fluid">
          <div className="columns">
           <div className="column is-2 removePad" >
           <img id="sfdcNav" alt="salesforce-services-icon" src="//images.ctfassets.net/qgwci0hijhhz/3Xtc8LSERBGSdc0fYw4MqL/9edeaa2e70f239cf06a6a56c4aa91379/salesforce-icons.png"/>
           </div>

            <div className="column is-2 removePad" >
          { data ? data.allContentfulServicePages.edges.map((item,index) => (
            item.node.title.includes('Salesforce') || item.node.title.includes('Community') ?
            <div key={index}>
              <Link onMouseOver={handleHover} onMouseLeave={exitHover} className="navbar-item dropdown-item" key={index} to={"/services/"+ item.node.slug}>{item.node.title}</Link>
            </div>
             : 
              ""
            
          
          )):""}
          </div>
          <div className="column is-3 removePad" >
          <img id="marketoNav" className="padLeft" alt="marketo-services-icon" src="//images.ctfassets.net/qgwci0hijhhz/7LRN67lepcwpfkOpE6IXb/2f40129712c30099a2bbb717e8b3a610/iconfinder_marketo.png"/>
           </div>
          <div className="column is-2 removePad" >
          { data ? data.allContentfulServicePages.edges.map((item,index) => (
            item.node.title.includes('Marketo') ?
            <div key={index}>
              <Link onMouseOver={handleHover} onMouseLeave={exitHover} className="navbar-item dropdown-item" key={index} to={"/services/"+ item.node.slug}>{item.node.title}</Link>
            </div>
             : 
              ""
            
          
          )):""}
          </div>

          </div>
          </div>
            </div>

          </div>
          </div>
          
          <Link className="navbar-item" to="/about/">
            About
          </Link>
          {/*<Link className="navbar-item" to="/pricing">
              Pricing
    </Link> */}
          <Link className="navbar-item" to="/blog/">
            Blog
          </Link>
          <div className="navbar-item">
            <div className="field is-grouped">
              <p className="control">
                <Link className="button is-primary is-outlined" to="/contact/">
                  Contact Us
                </Link>
              </p>
            </div>
          </div>
        </div>
      
    </nav>

  </Slide>


);



export default NavBar;

