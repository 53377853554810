module.exports = {
  title: "Salesforce. Marketo. Consulting. Managed Services. ",
  servicesHeader: "BUSINESS APPLICATIONS - PROFESSIONAL SERVICES",
  siteTitle: "Ideal Ops | Experts in Salesforce, Marketo, Consulting & Managed Services", // Site title.
  siteTitleAlt: "Ideal Ops", // Alternative site title for SEO.
  siteLogo: "/src/assets/img/idealops.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.ideal-ops.com", // Domain of your website without pathPrefix.
  pathPrefix: "", // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription:
    "We're Ideal Ops – a Salesforce, Marketo, and Managed Services company. We provide freelance resources to support Sales and Marketing Technology Operations in your organization.", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml",
  siteFBAppID: "427019505092717", // FB Application ID for using app insights
  googleTagManagerID: "GTM-MGT5C7N", // GTM tracking ID.
  disqusShortname: "ideal-ops-com", // Disqus shortname.
  userName: "Tyler Harris",
  userTwitter: "OpsSfdc",
  userLocation: "Austin, TX",
  userDescription:
    "Honest professional. Striving to make CRM and Marketing Technology Work for you.",
  copyright: "Copyright © Ideal Ops Business 2020-2021. All Rights Reserved.", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#384249", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff", // Used for setting manifest background color.
};
