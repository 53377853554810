import React, { Component } from "react";
import Helmet from "react-helmet";
import "../../assets/sass/styles.sass";
import config, { servicesHeader } from "../../../config";
import NavBar from "../NavBar";
import Footer from "../Footer";
import Spinner from "../Spinner";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = { isActive: false };
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleMobileVisibility = this.toggleMobileVisibility.bind(this);
    this.handleHover = this.handleHover.bind(this);

    this.state = {
    visible: true,
    dropArrow: false,
    isHidden: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);



  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);


  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos || currentScrollPos <= 7 ;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };

  handleHover = () =>{
    this.setState({dropArrow: true});
  }

  exitHover = () =>{
    this.setState({dropArrow: false});
  }

  

  toggleNavbar() {
    this.setState({ isActive: !this.state.isActive });
  }

  toggleMobileVisibility(){
  this.setState(this.state.isHidden ? {isHidden: false} : {isHidden: true});

  } 

  render() {
    
    return (
      
      <div id="layout-wrapper">

        <Helmet>
          <title>{config.siteTitle}</title>
          <meta name="description" content={config.siteDescription} />
        </Helmet>

        <NavBar
          isVisible={this.state.visible}
          handleHover={() => this.handleHover()}
          exitHover={() => this.exitHover()}
          isActive={this.state.isActive}
          toggleNavbar={() => this.toggleNavbar()}
          toggleDropDown={() => this.toggleDropDown()}
          isHidden={this.state.isHidden}
          dropArrow={this.state.dropArrow}
          toggleMobileVisibility={() => this.toggleMobileVisibility()}
        />
        <div id="content-wrapper">{this.props.children}</div>

        <Footer />
      </div>
    );
  }
}

export default Layout;
